import React from "react";
import styled from "styled-components";
import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import "./contact.css";
import Logo from "../components/logo";

const Contacts = () => (
  <Grid className="gridcont">
    <Text>
      <Subtitle>Contatti</Subtitle>
      <TextSmall>
        <Badge className="badge">
          <PhoneIcon />
        </Badge>
        <a href="tel:+393311461547">3311461547</a>
      </TextSmall>
      <TextSmall>
        <Badge className="badge">
          <MailIcon />
        </Badge>
        <a href="mailto:giada.meo@hotmail.it">giada.meo@hotmail.it</a>
      </TextSmall>
      <TextSmall>
        <Badge className="badge">
          <LocationOnIcon />
        </Badge>
        <a href="https://goo.gl/maps/zidBa2oeiLno24ea7">
          Studio-Hermes <br />
          Corso Centocelle 18/C, Civitavecchia (RM)
        </a>
      </TextSmall>
    </Text>

    <Text>
      <Subtitle>Social</Subtitle>
      <TextSmall>
        <Badge className="badge">
          <FacebookIcon />
        </Badge>
        <a href="https://www.facebook.com/ostetricagiadameo">
          Ostetrica Giada Meo
        </a>
      </TextSmall>
      <TextSmall>
        <Badge className="badge">
          <InstagramIcon />
        </Badge>
        <a href="https://www.instagram.com/ostetricagiada/?hl=it">
          @ostetricagiada
        </a>
      </TextSmall>
    </Text>
    <Logo />
  </Grid>
);

export default Contacts;

const Grid = styled.div`
  margin-top: 2rem;
  display: grid;
  background-color: white;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto 50%;
  grid-gap: 4vh;
`;

const Text = styled.div`
  margin-top: 2vh;
  padding-left: 2vw;
  line-height: 100%;
  color: #03363d !important;
  font-size: 3rem !important;
  font-weight: 600 !important;
`;

const TextSmall = styled.h4`
  color: black;
  margin-top: 2rem;
  font-weight: normal;
`;

const Subtitle = styled.h3`
  color: #03363d !important;
  font-size: 2rem !important;
  font-weight: 600 !important;
  margin-bottom: 0.6rem !important;
`;
