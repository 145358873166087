import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo/logo.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 540, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <ImgWrapper>
      <LogoWrapper fluid={data.placeholderImage.childImageSharp.fluid} />
    </ImgWrapper>
  );
};

export default Logo;

const LogoWrapper = styled(Img)`
  height: 10rem;
  width: 10rem;
`;

const ImgWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`;
